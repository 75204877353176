<template>
  <div class="hero__section">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="d-flex align-items-center justify-content-center">
            <h1>
              <span class="hero__text">
                {{text}}
              </span>
            </h1>
          </div>
          <div class="scroll__mouse">
            <a href="#" class="scroll-downs">
              <div class="mousey">
                <div class="scroller"></div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['text']
  };
</script>
