<template>
  <!-- Footer Start-->
  <footer class="main-footer">
    <div class="location__section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="location__slider slder">
              <div class="location__list">
                <div class="location__thumb">
                    <span class="location__text">
                      <img src="../../../assets/img/muc_font.svg" alt="img" class="img-fluid location-text">
                    </span>
                  <img src="../../../assets/img/munich_img.png" class="img-fluid location-img" alt="img">
                </div>
              </div>
              <div class="location__list">
                <div class="location__thumb">
                      <span class="location__text">
                        <img src="../../../assets/img/coburg_font.svg" alt="img" class="img-fluid location-text">
                      </span>
                  <img src="../../../assets/img/coburg_Img.png" class="img-fluid location-img" alt="img">
                </div>
              </div>
              <div class="location__list">
                <div class="location__thumb">
                        <span class="location__text">
                          <img src="../../../assets/img/world_font.svg" alt="img" class="img-fluid location-text">
                        </span>
                  <img src="../../../assets/img/world_img.png" class="img-fluid location-img" alt="img">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <a href="https://de.linkedin.com/company/baltys-software-gmbh" target="_blank">
        <span class="mdi mdi-linkedin linkedin-footer"></span>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="footer-links d-flex justify-content-center">
            <ul>
              <li>Copyright <span id="year">{{getYear()}}</span></li>
              <li><router-link to="/contact">Kontakt</router-link></li>
              <li><router-link to="/imprint">Impressum</router-link></li>
              <li><router-link to="/privacy">Datenschutz</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="elevator_item"> <a id="elevator" @click="scrollToTop" title="Back To Top"><img src="../../../assets/img/scroll-arrow.png" alt="img"></a>
    </div>
  </footer>
  <!-- Footer Close-->
</template>
<script>
import JQuery from 'jquery'
import slick from '../../../assets/js/slick'
let $ = JQuery;
$.slick = slick;
export default {
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    getYear()  {
      return new Date().getFullYear();
    }
  },
  mounted() {


    //Get the button
    var mybutton = document.getElementById("elevator_item");

    $('.location__slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: false,
      autoplay: false,
      dots: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots:true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots:true
          }
        }
      ]
    })





    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
  }
};
</script>
