import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  state: {
    source: null,
    isDev: (document.location.href.toLowerCase().indexOf('dev.') !== -1)
  },
  mutations: {
    source(state, src) {
      state.source = src
    }
  },
  actions: {
  }
})

export default store
