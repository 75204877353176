<template>
  <div>
    <app-header />
    <div class="base-layout">
      <router-view />
      <Footer />
    </div>
  </div>
</template>
<script>
import appHeader from "../components/Header/LmsHeader";
import Footer from "../components/Footer/Footer";
export default {
  components: {
    appHeader,
    Footer
  }
};
</script>
