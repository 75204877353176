var allTexts = [
    ['blabla','tacheles'],
    ['wirrwarr','klarheit'],
    ['tickets','antworten'],
    ['probleme','lösungen'],

]

var tt1 = null
var tt2 = null
var id = null
if (tt2) {
    console.log('just testing')
}
var deleted = 0
var rotatorIndex = 0
var animText1 = ''
var animText2 = ''
var TxtType1 = function () {
    this.text = animText1
    this.el = document.getElementsByClassName('typewrite')[0]
    this.period = 4000
    this.txt = ''
    this.tick()
    this.isDeleting = false
}

TxtType1.prototype.tick = function () {
    if (!this.el) {
        return
    }
    var fullTxt = this.text

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1)
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1)
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>'

    var that = this
    var delta = 250 - Math.random() * 100

    if (this.isDeleting) { delta /= 2 }

    if (!this.isDeleting && this.txt === fullTxt) {
      this.isDeleting = true
      id = setTimeout(function () {
        tt2 = new TxtType2()
      }, 1000)
    }  else {
      if (this.isDeleting && this.txt.length === 0 ) {
        deleted++
        if (deleted === 2) {
          deleted = 0
          rotateTyper()
        }
      } else {
        id = setTimeout(function () {
          that.tick()
        }, delta)
      }
    }
}

var TxtType2 = function () {
    this.text = animText2
    this.el = document.getElementsByClassName('typewrite')[1]
    this.period = 4000
    this.txt = ''
    this.tick()
    this.isDeleting = false
}

TxtType2.prototype.tick = function () {
    if (!this.el) {
        return
    }
    var fullTxt = this.text

    if (this.isDeleting) {
      this.txt = fullTxt.substring(0, this.txt.length - 1)
    } else {
      this.txt = fullTxt.substring(0, this.txt.length + 1)
    }

    this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>'

    var that = this
    var delta = 250 - Math.random() * 100

    if (this.isDeleting) { delta /= 2 }

    if (!this.isDeleting && this.txt === fullTxt) {
      this.isDeleting = true
      id = setTimeout(function () {
        tt1.tick()
        id = setTimeout(function () {
          that.tick()
        }, delta)
      }, 2000)
    } else {
      if (this.isDeleting && this.txt.length === 0 ) {
        deleted++
        if (deleted === 2) {
          deleted = 0
          rotateTyper()
        }
      } else {
        id = setTimeout(function () {
          that.tick()
        }, delta)
      }
    }
}

function rotateTyper(time) {
    if (typeof time === 'undefined') {
      time = 2000
    }

    animText1 = allTexts[rotatorIndex][0]
    animText2 = allTexts[rotatorIndex][1]
    if (rotatorIndex === allTexts.length-1) {
      rotatorIndex = 0
    } else {
      rotatorIndex ++
    }
    id = setTimeout(function () {
      tt1 = new TxtType1()
    }, time)
    tt2 = null
}

function stopTimeouts() {
    while (id) {
        window.clearTimeout(id);
        id--
    }
}

module.exports = { rotateTyper, stopTimeouts }