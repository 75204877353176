import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/lms/Home.vue";
import MoodleIlias from "../views/lms/MoodleIlias.vue";
import LmsTest from "../views/lms/LmsTest.vue";
import VueCarousel from 'vue-carousel';
import Contact from "../views/Contact";
import Imprint from "../views/Imprint";
import Privacy from "../views/Privacy";

Vue.use(VueCarousel);

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home
    },
    {
        path: "*",
        redirect: '/'
    },
    {
        path: "/lms-entwicklung",
        name: "MoodleIlias",
        component: MoodleIlias
    },
    {
        path: "/lms-testversion",
        name: "LmsTest",
        component: LmsTest
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact
    },
    {
        path: "/imprint",
        name: "Imprint",
        component: Imprint
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: Privacy
    },
]

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
});

export default router;
