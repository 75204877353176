<template>
  <div id="Home">
    <hero v-bind:text="'Ihre Ziele sind unsere Leidenschaft – Mit uns gelingt Ihr e-Learning'"/>
    <typing />
    <service />
    <contact v-bind:heading="'JETZT UNVERBINDLICHE ANFRAGE SENDEN'" v-bind:text="'Sie haben Fragen oder möchten uns eine unverbindliche Anfrage senden? Schreiben Sie uns einfach eine Nachricht, wir freuen uns auf Sie.'" v-bind:buttonlabel="'Jetzt Anfrage senden'"/>
    <testimonial />
  </div>
</template>

<script>
import Hero from "../components/Home/Hero";
import Typing from "../components/Home/Typing";
import Service from "../components/Home/Service";
import Contact from "../components/Contact";
import Testimonial from "../components/Testimonial";
import { rotateTyper, stopTimeouts} from "../../assets/js/typer"

export default {
  data() {
    return {
      timers: null
    }
  },
  components: {
    Hero,
    Typing,
    Service,
    Contact,
    Testimonial
  },
  mounted() {
    var elements = document.getElementsByClassName('typewrite')
    if (elements.length >= 2) {
      this.timers = rotateTyper(0)
    }
    // INJECT CSS
    var css = document.createElement('style')
    css.type = 'text/css'
    css.innerHTML = '.typewrite > .wrap { border-right: 0 solid #fff}'
    document.body.appendChild(css)
  },
  beforeDestroy() {
    stopTimeouts()
  }
};
</script>
