import Vue from 'vue'
import baseApp from './App.vue'
import lmsApp from './LmsApp.vue'
import mainRouter from "./router/index";
import lmsRouter from "./router/lms";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../assets/scss/index.scss';
import '../assets/css/style.css';
import '../assets/css/slick.css';
import '../assets/css/slick-theme.css';
import '../assets/css/responsive.css';
import '../assets/css/materialdesignicons.min.css';
import store from './store'
Vue.config.productionTip = false

let App = baseApp
let router = mainRouter

if (document.location.href.toLowerCase().indexOf('.s3-website.') !== -1) {
  document.location.href = 'https://www.baltys.de'
}

if (document.location.href.toLowerCase().indexOf('lms-profis.') !== -1) {
  router = lmsRouter
  App = lmsApp
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
