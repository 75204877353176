<template>
  <!-- Contact Section Start -->
  <div class="contact__sec" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="underline">{{heading}}</h2>
          <div class="contact__details">
            <h3>{{text}}</h3>
            <router-link to="/contact" class="bttn bttn-outline-secondary no-underline">{{buttonlabel}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Contact Section Close -->
</template>
<script>
  export default {
    props: ['heading','text','buttonlabel']
  };
</script>
