<template>
    <header class="main-header pb-0 home__header">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 d-flex justify-content-between align-items-center">
                    <div class="logo-sec">
                        <router-link to="/" class="logo">
                            <img
                                    src="../../../assets/img/baltys_elearning_logo.svg"
                                    alt="Baltys Software GmbH"
                                    class="img-fluid"
                            />
                        </router-link>
                    </div>
                    <div class="menu-links d-flex justify-content-between align-items-center">
                        <ul class="menu d-flex">
                            <li>
                                <router-link @click.native="toggle" to="/">Home</router-link>
                            </li>
                            <li>
                                <router-link @click.native="toggle" to="/lms-entwicklung">LMS: Moodle, ILIAS & Co</router-link>
                            </li>
                            <li>
                                <router-link @click.native="toggle" to="/lms-testversion">LMS Testen</router-link>
                            </li>

                            <li>
                                <router-link @click.native="toggle" to="/contact">KONTAkT</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <a @click="toggle()" class="menu-icon cd-dropdown-trigger" :class="{'active': active}">
            <span></span>
        </a>
    </header>
</template>
<script>
    export default {
        data() {
            return {
                isDev: this.$store.state.isDev,
                active: false
            };
        },
        methods: {
            toggle() {
                this.active = !this.active;
                let sitebody = document.body;
                this.active
                    ? sitebody.classList.add("menu-in")
                    : sitebody.classList.remove("menu-in");
            }
        }
    };
</script>
