import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Contact from "../views/Contact.vue";
import Imprint from "../views/Imprint.vue";
import Privacy from "../views/Privacy.vue";
import VueCarousel from 'vue-carousel';
import MoodleIlias from "@/views/MoodleIlias";
import Ac5 from "@/views/AC5";
import eTrainings from "@/views/Etrainings";
import mahara from "@/views/Mahara";

Vue.use(VueCarousel);

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/lms-entwicklung",
    name: "MoodleIlias",
    component: MoodleIlias
  },
  {
    path: "/academy-five",
    name: "AcademyFive",
    component: Ac5
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/e-trainings",
    name: "E-Training",
    component: eTrainings
  },
  {
    path: "/mahara",
    name: "Mahara",
    component: mahara
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy
  },
  {
    path: "*",
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});

export default router;
