export default function (name, email, msg, src) {
  return new Promise(function (resolve, reject) {
    let emailMessage
    if (src && src !== '') {
      emailMessage = 'source:' + src + '. ' + msg
    } else {
      emailMessage = msg
    }
    var payload = {
      name: name,
      email: email,
      message: emailMessage
    };
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        if(this.status == 200) {
          resolve()
        } else {
          reject()
        }
      }
    };
    xhttp.open(
      "POST",
      "https://3vnuck2dla.execute-api.eu-central-1.amazonaws.com/prod/v1/form/mail",
      true
    );
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send(JSON.stringify(payload));
    if (window.gtag) {
      window.gtag("event", "click_button", {
        event_category: "contact_form",
        event_label: "send"
      });
    }
  })
}
