<template>
  <div>
    <!-- Our Service Section Start-->
    <div class="our__service__sec" style="padding-top:2rem;padding-bottom:0px;">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="underline">E-Learning Kurse</h1>
            <h3>E-Learning … und es hat zapp gemacht!</h3>
          </div>
        </div>
        <div class="row justify-content-center" style="margin-top: 3rem;">
          <div class="content-img-sec">
            <div class="row m-0 align-items-center">
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-right-sec moodle-content">
                  <div class="project-box-sec content-left">
                    <div class="project-box-details">
                      <h4>Individuelle e-Learnings</h4>
                      <p><br/>
                        Unsere Tochterfirma Zapp eLearning GmbH steht seit 1999 für die Entwicklung und Umsetzung professioneller Lerninhalte. Dabei erstellen wir auf jeden Kunden speziell zugeschnittene interaktive Lernmodule. Im Moodle direkt, im eigenen HTML5-Player Framework oder in marktüblicher Autorensoftware, beispielsweise Articulate Storyline.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-left-sece">
                  <img src="../../assets/img/etraining_1.jpg" class="img-fluid" alt="img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="content-img-sec">
            <div class="row m-0 align-items-center">
              <div class="col-md-12 col-lg-6 p-0 order-2 order-lg-1">
                <div class="content-right-sece">
                  <img src="../../assets/img/etraining_2.jpg" class="img-fluid" alt="img"/>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 p-0 order-1 order-lg-2">
                <div class="content-left-sec moodle-content">
                  <div class="project-box-sec content-left">
                    <div class="project-box-details">
                      <h4>Webbasierte und klassische E-Learning Kurse</h4>
                      <p>
                        <br/>
                        Webbasierte E-Learning Kurse sind die kostengünstige Möglichkeit, um Team und Trainer professionell zu schulen. Alles ist möglich – barrierefreie Selbstlernkurse, detailgetreu animierte Service-, Produkt-  oder Softwareschulungen mit interaktiven Elementen oder auch verpflichtende Fortbildungen mit Zertifikat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-img-sec">
            <div class="row m-0 align-items-center">
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-right-sec moodle-content">
                  <div class="project-box-sec content-left">
                    <div class="project-box-details">
                      <h4>Videobasierte E-Learning Kurse</h4>
                      <p><br/>In der Personalentwicklung sind E-Learning Kurse mit integrierten Videomodulen besonders effektiv. Mit fesselnden Dialogen und Szenen machen wir typische Situationen erlebbar, bei denen eigene Entscheidungen spielerisch simuliert und hinterfragt werden können. So werden die Teilnehmenden praxisnah und realitätsgetreu geschult.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-left-sece">
                  <img src="../../assets/img/etraining_3.jpg" class="img-fluid" alt="img"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 1.5rem">
          <div class="col-12">
            <p>Unsere Begleitung beginnt mit der ersten Konzeptidee und endet erst mit der Implementierung in
              Ihrem System. Wir beraten Sie dazu gerne, mit welchen Formaten und Inhalten Sie Ihre Zielgruppen am
              besten erreichen können.</p>
          </div>
        </div>
        <div class="row" style="margin: 1.5rem 0">
          <div class="col-12">
            <p>Unsere Leistungen umfassen unter anderem:</p>
            <ul class="bullist">
              <li>Gliederung und Konzeption von Lerninhalten</li>
              <li>Erstellen von Drehbüchern</li>
              <li>Übersetzungen und Lektorat</li>
              <li>Erstellen von Animationen und Grafiken inklusive Bildbearbeitung</li>
              <li>Sprachaufnahmen mit Profisprechern</li>
              <li>Optimierungen speziell für mobile Endgeräte aller Art</li>
              <li>Lieferung SCORM-fähiger E-Learning Module nach HTML5-Standard</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <testimonial/>
    <contact style="margin-bottom: 3rem;" v-bind:heading="'UND WAS KÖNNEN WIR FÜR SIE TUN?'"
             v-bind:buttonlabel="'Sprechen Sie uns an!'"
             v-bind:text="'Geht nicht? Gibt\'s nicht! Wir finden auch für Ihre Herausforderungen praktikable Lösungen'"/>


  </div>
</template>

<script>
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";

export default {
  async beforeMount() {
    if (this.$router.currentRoute.query.source) {
      this.$store.commit('source', this.$router.currentRoute.query.source)
    }
  },
  components: {
    Testimonial,
    Contact
  }
};
</script>
