<template>
  <header class="main-header pb-0 home__header">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 d-flex justify-content-between align-items-center">
          <div class="logo-sec">
            <router-link to="/" class="logo">
              <img
                src="../../../assets/img/baltys_elearning_logo.svg"
                alt="Baltys Software GmbH"
                class="img-fluid"
              />
            </router-link>
          </div>
          <div class="menu-links d-flex justify-content-between align-items-center">
            <ul class="menu d-flex">
              <li>
                <router-link @click.native="toggle" to="/">Home</router-link>
              </li>
              <li>
                <router-link @click.native="toggle" to="/lms-entwicklung">Moodle/LMS</router-link>
              </li>
              <li>
                <router-link @click.native="toggle" to="/mahara">Mahara</router-link>
              </li>
              <li>
                <router-link @click.native="toggle" to="/academy-five">academyFIVE</router-link>
              </li>
              <li>
                <router-link @click.native="toggle" to="/e-trainings">E-Learnings</router-link>
              </li>
              <li>
                <router-link @click.native="toggle" to="/contact">Kontakt</router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <a @click="toggle()" class="menu-icon cd-dropdown-trigger" :class="{'active': active}">
      <span></span>
    </a>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isDev: this.$store.state.isDev,
      active: false
    };
  },
  methods: {
    toggle() {
      this.active = !this.active;
      let sitebody = document.body;
      this.active
        ? sitebody.classList.add("menu-in")
        : sitebody.classList.remove("menu-in");
    },
    goToCredo() {
      this.active = false
      document.body.classList.remove("menu-in")
      if (this.$route.fullPath == '/#credo') {
        document.getElementById('credo').scrollIntoView()
      } else {
        this.$router.push('/#credo')
        setTimeout(function() {
          document.getElementById('credo').scrollIntoView()
        })

      }
    },
    goToService() {
      this.active = false
      document.body.classList.remove("menu-in")
      if (this.$route.fullPath == '/#service') {
        document.getElementById('service').scrollIntoView()
      } else {
        this.$router.push('/#service')
        setTimeout(function() {
          document.getElementById('service').scrollIntoView()
        })

      }
    },
    goToLMS() {
      this.active = false
      document.body.classList.remove("menu-in")
      if (this.$route.fullPath !== '/contact') {
        this.$router.push('/contact')
      }
    },
    goToContact() {
      this.active = false
      document.body.classList.remove("menu-in")
      if (this.$route.fullPath !== '/contact') {
        this.$router.push('/contact')
      }
    },
    goToHome() {
      this.active = false
      document.body.classList.remove("menu-in")
      if (this.$route.fullPath !== '/') {
        this.$router.push('/')
      }
    }
  }
};
</script>
