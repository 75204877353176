<template>
  <div>
    <!-- Our Service Section Start-->
    <div class="our__service__sec" style="padding-top:2rem;padding-bottom:0px;">
      <div class="container">
        <div class="row" style="margin-bottom: 3rem;">
          <div class="col-sm-12">
            <h1 class="underline">Betreung und Entwicklung für Mahara</h1>
            <h4>Konzept - Entwicklung - Implementierung - Schulung - Wartung - Hosting</h4>
            <p>Mahara ist eine persönliche Lernumgebung. In einem individuellen E-Portfolio sammeln die Benutzer
              Mitschriften und Ausführungen zu Seminaren und Kursen. Mahara ist damit die perfekte Ergänzung zu
              Online-Lernplattformen wie Moodle. Notizen, Fotos und weitere Materialien können in Lerngruppen
              eingestellt und so zu gemeinsamen Resümees verdichtet werden. Auch für die persönliche Revision der
              Inhalte ist die übersichtliche Aufbereitung des Systems perfekt geeignet.</p>

            <p>In Blogposts können Ideen und Überlegungen dem gesamten Seminar oder kleineren Lerngruppen zugänglich
              gemacht werden. Networking-Features erleichtern es, einzelne Inhalte auszutauschen. Mahara ist ideal für
              die gemeinschaftliche Nachbereitung von Kursen und die Vorbereitung auf Prüfungen. Ob Uni, Schule oder
              Fortbildungsseminar, Mahara passt perfekt zu Ihren Online-Kursen.</p>
          </div>
        </div>
        <div class="row justify-content-center" style="margin-bottom: 3rem;">
          <div class="content-img-sec">
            <div class="row m-0 align-items-center">
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-right-sec moodle-content">
                  <div class="project-box-sec content-left">
                    <div class="project-box-details">
                      <h4>MIT MAHARA ZUM EIGENEN E-PORTFOLIO</h4>
                      <p><br/>
                        Die Erstellung, Dokumentation und Veröffentlichung eigener Kompetenz-Profile wird zunehmend wichtiger.
                        Spätestens seit der Bologna-Reform werden nicht nur bei Bewerbungen, sondern auch in Schulen und Universitäten nebst der Fach-Qualifikation außerfachliche Fähigkeiten wie zum Beispiel Persönliche und Soziale Kompetenzen gefordert.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-left-sece">
                  <img src="../../assets/img/mahara-1.jpg" class="img-fluid" alt="img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="content-img-sec">
            <div class="row m-0 align-items-center">
              <div class="col-md-12 col-lg-6 p-0 order-2 order-lg-1">
                <div class="content-right-sece">
                  <img src="../../assets/img/mahara-2.jpg" class="img-fluid" alt="img"/>
                </div>
              </div>
              <div class="col-sm-12 col-lg-6 p-0 order-1 order-lg-2">
                <div class="content-left-sec moodle-content">
                  <div class="project-box-sec content-left">
                    <div class="project-box-details">
                      <h4>VOM TOOL ZUM GESAMTKONZEPT</h4>
                      <p>
                        <br/>
                        Mahara funktioniert standalone. Doch der Charme liegt in der Integration der Systeme. Zum Beispiel per Single SignOn zum LMS. Oder als Teil des Lernpfades. Sie entscheiden, wohin die Reise geht. Wir sorgen dafür, dass aus einzelnen Tools ein Gesamtkonzept entsteht.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-img-sec">
            <div class="row m-0 align-items-center">
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-right-sec moodle-content">
                  <div class="project-box-sec content-left">
                    <div class="project-box-details">
                      <h4>AUF PROJEKTBASIS ODER AUF DAUER</h4>
                      <p><br/>Baltys ist schnittstellenkompatibel. Nicht nur was Ihre Systemlandschaften betrifft, sondern auch in der Art der Zusammenarbeit: Auf Projektbasis, als Sparringpartner oder als Fullservice-Dienstleister. Nur das Ergebnis zählt!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-lg-6 p-0">
                <div class="content-left-sece">
                  <img src="../../assets/img/mahara-3.jpg" class="img-fluid" alt="img"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <testimonial/>


      <contact style="margin-bottom: 3rem;" v-bind:heading="'UND WAS KÖNNEN WIR FÜR SIE TUN?'"
               v-bind:text="'Bei uns stehen Sie und Ihr Projekt im Mittelpunkt. Entdecken Sie uns als attraktive Alternative zu einem Moodle-Partner'"
               v-bind:buttonlabel="'Sprechen Sie uns an!'"/>

    </div>


  </div>
</template>

<script>
import Testimonial from "../components/Testimonial";
import Contact from "../components/Contact";

export default {
  async beforeMount() {
    if (this.$router.currentRoute.query.source) {
      this.$store.commit('source', this.$router.currentRoute.query.source)
    }
  },
  components: {
    Testimonial,
    Contact
  }
};
</script>
