<template>
    <div>
        <!-- Our Service Section Start-->
        <div class="our__service__sec" style="padding-top:2rem;padding-bottom:0px;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="underline">LMS Testversion</h1>
                        <h3>Testen Sie kostenlos und unverbindlich Ihr eigenes LMS</h3>
                    </div>
                </div>
                <div class="row justify-content-center" style="margin-top: 3rem;">
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-right-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>Testen und dann entscheiden</h4>
                                            <p><br/>Sie m&ouml;chten ein Open Source LMS einsetzen, wissen aber nicht, ob das System für Sie geeignet ist? Wir stellen Ihnen gerne eine eigene Test-Umgebung zur Verfügung, in welcher Sie sich in Ruhe mit dem LMS vertraut machen k&ouml;nnen. Ohne Vertragsbindung oder Druck. Sie entscheiden selbst nach 60 Tagen, ob Sie das System weiter verwenden wollen.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-left-sece">
                                    <img src="../../../assets/img/ac5_verbunden.jpg" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 1.5rem">
                    <div class="col-12">
                        <p>&nbsp;</p>
                    </div>
                </div>
            </div>
        </div>

      <contact style="margin-bottom: 3rem;" v-bind:heading="'Fordern Sie jetzt Ihre LMS-Testversion an!'" v-bind:buttonlabel="'Jetzt anfragen'" v-bind:text="'Kontaktieren Sie uns, und Sie erhalten in der Regel innerhalb weniger Werktage Ihre eigene Testversion.'"/>
      <testimonial/>



    </div>
</template>

<script>
    import Testimonial from "../../components/Testimonial";
    import Contact from "../../components/Contact";
    export default {
        async beforeMount() {
            if (this.$router.currentRoute.query.source) {
                this.$store.commit('source', this.$router.currentRoute.query.source)
            }
        },
        components: {
            Testimonial,
            Contact
        }
    };
</script>
