<template>
  <div>
    <hero v-bind:text="'Ihr fairer IT-Partner, wenn es um e-Learning geht'"/>
    <!-- Our Service Section Start-->
    <div class="our__service__sec" id="service">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h2 class="underline">UNSERE LEISTUNGEN</h2>
            <p>Wir von der Baltys Software GmbH sind Ihr kompetenter Dienstleister auf Augenhöhe, wenn es um die Beratung, Entwicklung, Anpassung und Wartung von e-Learning Plattformen geht. Insbesondere mit Moodle, Ilias und academyFIVE verfügen wir über langjährige Erfahrung und stehen Ihnen bei Ihren Ideen mit Rat und Tat zur Seite.<br/><br/>Als kleines, daher aber flexibles Team können wir uns ganz auf Ihre Wünsche und Ihre Ausgangssituation einstellen, da uns keine starren Konzepte oder festgelegte Organisationsworkflows einengen. Bei uns stehen Sie und Ihr Projekt im Mittelpunkt, pers&ouml;nliche Ansprechpartner geh&ouml;ren bei uns zum guten Ton.<br/><br/>
              Auch die IU internationale Hochschule zählt zu unseren zufriedenen Kunden. Als Deutschlands größte private Hochschule für Online-Studium mit über 75.000 Studierenden vertraut sie uns seit 2016 in Sachen Software-Entwicklung f&uuml;r die auf Moodle basierende e-Learning Plattform.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="project-box-sec zoom-effects">
              <div class="project-box-thumb">
                <img src="../../../assets/img/icon6.png" class="img-fluid" alt="img" />
              </div>
              <div class="project-box-details">
                <h4>Exzellente Beratung</h4>
                <p>Sie haben neue Ideen oder aktuelle Herausforderungen? Wir beraten Sie und zeigen Ihnen mögliche Lösungswege auf. Durch unsere zielgerichtete Herangehensweise erarbeiten wir mit Ihnen wirtschaftliche und nachhaltige Lösungsansätze.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="project-box-sec zoom-effects">
              <div class="project-box-thumb">
                <img src="../../../assets/img/icon2.png" class="img-fluid" alt="img" />
              </div>
              <div class="project-box-details">
                <h4>Clevere Schnittstellen</h4>
                <p>Die IT-Landschaft jeder Bildungseinrichtung ist so einzigartig wie ihr Bildungsangebot. Durch unsere Schnittstellen können Ihre Systeme bequem Daten austauschen - dadurch werden langwierige manuelle Prozesse &uuml;berfl&uuml;ssig.</p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <div class="project-box-sec zoom-effects">
              <div class="project-box-thumb">
                <img src="../../../assets/img/icon1.png" class="img-fluid" alt="img" />
              </div>
              <div class="project-box-details">
                <h4>Moderne Systeme</h4>
                <p>Ein erstklassiger Lehrbetrieb benötigt fortschrittliche e-Learning Systeme. Wir helfen Ihnen &ndash;durch den Einsatz von modernen Tools&ndash; Trends nicht zu verpassen und bei Innovationen vorne mit dabei zu sein.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Our Service Section Close-->
    <testimonial/>
    <contact style="margin-bottom: 3rem;" v-bind:text="'Sie haben Fragen oder möchten uns eine unverbindliche Anfrage senden? Schreiben Sie uns einfach eine Nachricht, wir freuen uns auf Sie.'" v-bind:heading="'JETZT UNVERBINDLICHE ANFRAGE SENDEN'" v-bind:buttonlabel="'Anfrage senden'"/>
  </div>
</template>

<script>
  import Testimonial from "../../components/Testimonial";
  import Contact from "../../components/Contact";
  import Hero from "../../components/Home/Hero";

  export default {
    async beforeMount() {
      if (this.$router.currentRoute.query.source) {
        this.$store.commit('source', this.$router.currentRoute.query.source)
      }
    },
    components: {
      Hero,
      Testimonial,
      Contact
    }
  };
</script>
