<template>
    <div>
        <!-- Our Service Section Start-->
        <div class="our__service__sec" style="padding-top:2rem;padding-bottom:0px;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="underline">academyFive</h1>
                        <h3>Beratung und Schnittstellenentwicklung</h3>
                    </div>
                </div>
                <div class="row justify-content-center" style="margin-top: 3rem;">
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-right-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>Modulares Campus-Management-System</h4>
                                            <p><br/>
                                                Kein Zweifel, academyFIVE von Simovative ist eine Erfolgsstory. Keine andere Software
                                                integriert den akademischen Life Cycle so vollständig wie das Campus Management AC5.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-left-sece">
                                    <img src="../../assets/img/ac5_campus.jpg" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0 order-2 order-lg-1">
                                <div class="content-right-sece">
                                    <img src="../../assets/img/ac5_schnittstellen.jpg" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-6 p-0 order-1 order-lg-2">
                                <div class="content-left-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>Individuelle Schnittstellen</h4>
                                            <p>
                                                <br/>
                                                Als IT-Partner von Simovative-Kunden liegt unsere Kompetenz in der Entwicklung von &quot;Middlewares&quot; zum Datenaustausch von Anwendungsprogrammen, die in heterogenen Umgebungen arbeiten.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-right-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>Verbundene Systemlandschaften</h4>
                                            <p><br/>Egal ob DATEV, Salesforce, Hubspot, Moodle, Tatora oder Blackboard. Die Systemlandschaft jeder Bildungseinrichtung ist so individuell wie sie selbst. Wir helfen unseren Kunden academyFIVE optimal mit ihren Drittsysteme zu verkn&uuml;pfen</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-left-sece">
                                    <img src="../../assets/img/ac5_verbunden.jpg" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 1.5rem">
                    <div class="col-12">
                        <p>Im Folgenden finden Sie einige Auszüge aus umgesetzten Kundenprojekten, damit Sie sich ein Bild machen können, wie vielseitig die Schnittstellen von academyFIVE eingesetzt werden k&ouml;nnen. Und auch wenn es laut Hersteller keine Lösung für Ihr Problem gibt, sprechen Sie uns ruhig an, gemeinsam finden wir alternative Lösungswege.</p>
                    </div>
                </div>
                <div class="row" style="margin: 1.5rem 0">
                    <div class="col-12">
                        <ul class="bullist">
                            <li>Übertragung von im LMS erlangten Noten zu academyFIVE</li>
                            <li>Automatisiertes Anlegen von Interessenten, Studenten und Dozenten in AC5</li>
                            <li>Provisionierung von neuen Studierenden in Drittsysteme</li>
                            <li>Setzen und Entziehen von Prüfungszulassungen</li>
                            <li>Kursbuchungen im LMS sobald in AcademyFive eine neue Kursbuchung vorliegt</li>
                            <li>Anzeige des Curriculums und Notenspiegels im externen LMS</li>
                            <li>Weiterleitung von Daten an externe Tools zur Rechnungsstellung</li>
                            <li>Anzeige von Klausurdaten auf der Homepage</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <testimonial/>
        <contact style="margin-bottom: 3rem;" v-bind:heading="'UND WAS KÖNNEN WIR FÜR SIE TUN?'" v-bind:buttonlabel="'Sprechen Sie uns an!'" v-bind:text="'Geht nicht? Gibt\'s nicht! Wir finden auch für Ihre Herausforderungen praktikable Lösungen'"/>


    </div>
</template>

<script>
    import Testimonial from "../components/Testimonial";
    import Contact from "../components/Contact";
    export default {
        async beforeMount() {
            if (this.$router.currentRoute.query.source) {
                this.$store.commit('source', this.$router.currentRoute.query.source)
            }
        },
        components: {
            Testimonial,
            Contact
        }
    };
</script>
