<template>
    <div>
        <!-- Our Service Section Start-->
        <div class="our__service__sec" style="padding-top:2rem;padding-bottom:0px;">
            <div class="container">
                <div class="row" style="margin-bottom: 3rem;">
                    <div class="col-sm-12">
                        <h1 class="underline">LMS Entwicklung für Moodle, Totara & Co.</h1>
                        <h4>Konzept - Entwicklung - Implementierung - Schulung - Wartung - Hosting</h4>
                        <h3>Als unabhängiger IT-Dienstleister sind wir die clevere Alternative zu systemgebundenen Anbietern, egal ob Moodle, Ilias oder andere Lernmanagement-Systeme (LMS).</h3>
                        <p>Wir legen wenig Wert auf Status und Titel. Im Gegenteil, in der IT ist es oftmals ein Vorteil, nicht den "klassischen Weg" zu nehmen, um eine Aufgabe effizient und ohne Umwege zu lösen. Das gilt für uns als unabhängiger Dienstleister ebenso wie für Sie als ungebundener Auftraggeber. Was zählt, ist das Ergebnis. Und der Preis.</p>
                        <p>Wir eröffnen Ihnen neue Freiheitsgrade für individuelle Entwicklungen, jenseits von starren Prozessen, Lizenzgebühren und Budgets. Und um ehrlich zu sein, empfinden wir es jedesmal als Auszeichnung, wenn wir uns mit dieser Unabhängigkeit vor allem bei offiziellen Moodle-Partnern nicht beliebt machen.</p>
                    </div>
                </div>
                <div class="row justify-content-center" style="margin-bottom: 3rem;">
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-right-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>MIT EINEM LMS IDEEN VERWIRKLICHEN</h4>
                                            <p><br/>
                                                Jede Bildungseinrichtung hat ihre eigene Struktur, ihre eigene digitale
                                                Strategie und ihr einzigartiges Bildungskonzept.
                                                Ein LMS kann diese Bed&uuml;fnisse perfekt abbilden.
                                                Haben Sie bestimmte Ideen, die Sie z.B. mit einem LMS verwirklichen wollen?
                                                Fragen Sie uns. Wir kennen Moodle und Ilias in- und auswendig.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-left-sece">
                                    <img src="../../assets/img/moodle_plugins.png" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0 order-2 order-lg-1">
                                <div class="content-right-sece">
                                    <img src="../../assets/img/moodle_dev.png" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-6 p-0 order-1 order-lg-2">
                                <div class="content-left-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>VOM KONZEPT BIS ZUR UMSETZUNG</h4>
                                            <p>
                                                <br/>
                                                Ob Konzeption, Umsetzung oder Betreuung - Wir decken alle Aspekte Ihres
                                                Projektes ab. Gerne migrieren wir auch Ihr bestehendes LMS
                                                oder &uuml;bernehmen Ihr bereits bestehendes Moodle-System von einem anderen Dienstleister.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-img-sec">
                        <div class="row m-0 align-items-center">
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-right-sec moodle-content">
                                    <div class="project-box-sec content-left">
                                        <div class="project-box-details">
                                            <h4>FAIR UND FLEXIBEL</h4>
                                            <p><br/>Wir realisieren unsere Projekte mit einem kleinen,
                                                schlagkr&auml;ftigen Team. Ohne langwierige
                                                Abstimmungsprozesse, ohne sture Projekt-Templates, ohne überfl&uuml;ssigen
                                                Papierkram. F&uuml;r Sie bedeutet dies: Mehr Leistung und Betreuung für
                                                Ihr Geld.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 p-0">
                                <div class="content-left-sece">
                                    <img src="../../assets/img/moodle_fair.png" class="img-fluid" alt="img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top: 20px;">
                    <div class="col-12">
                        <p>Im Folgenden erhalten Sie einige, wenige Auszüge aus Projekten, damit Sie sich ein genaueres Bild über die Arbeit machen können. Klicken Sie hierfür einfach auf den jeweiligen Punkt um weitere Details zu erfahren. </p>
                    </div>
                </div>
                <div class="row" style="margin: 1.5rem 0;">
                    <div class="col-12">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="headingOne">
                                    <a data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                       aria-controls="collapseOne">
                                        Anbindung von Drittsystemen
                                    </a>
                                </div>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne"
                                     data-parent="#accordion">
                                    <div class="card-body">
                                        <ul class="bullist">
                                            <li>Anbindung von Drittsystemen zur Authentifizierung, zB. LDAP,
                                                OneLogin,
                                                SAML2 an Moodle
                                            </li>
                                            <li>Automatisierte Übertragung von erlangten Noten in Campus Management
                                                Systeme
                                            </li>
                                            <li>Übertragung von Daten aus und in AcademyFive, zB. Kursbuchungen</li>
                                            <li>Automatisierte Anbindung von Evasys Kursevaluationen an Moodle</li>
                                            <li>Automatischer Import von Webinar-Aufzeichnung in die jeweiligen
                                                Kurse
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card-header" id="headingTwo">
                                    <a data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                                       aria-controls="collapseTwo">
                                        Individuelle Moodle Module und Plugins
                                    </a>
                                </div>
                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                     data-parent="#accordion">
                                    <div class="card-body">
                                        <ul class="bullist">
                                            <li>Eigenes Dashboard für Dozenten mit diversen Hilfsmitteln für die
                                                Verwaltung
                                            </li>
                                            <li>Automatisierte Anzeige von Tutor-Profilen im Kurs</li>
                                            <li>Embedded Player für die Anbindung eines Videostreaming-Servers</li>
                                            <li>Spezielle Kursformate, um die Kursinhalte in einer optimierten
                                                Gliederung darzustellen
                                            </li>
                                            <li>Individuelle Themes um Moodle optisch an das Corporate Design
                                                anzupassen
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingThree">
                                    <a data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
                                       aria-controls="collapseThree">
                                        Hochverf&uuml;gbarkeits-Hosting und Updates
                                    </a>
                                </div>
                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                     data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Ausfallsicheres Hosting für einen reibungslosen Betrieb in einem deutschen Rechenzentrum. Gemeinsam mit unserem Partner Corpex Internet GmbH schaffen wir f&uuml;r Ihre Lernumgebung eine hochverfügbare und skalierbare L&ouml;sung.
                                            <br/><br/>
                                            Sie setzen bereits Moodle oder Ilias ein, und m&ouml;chten auf eine neue Version updaten? Sprechen Sie uns an, hier k&ouml;nnen wir Ihnen weiterhelfen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="headingFour">
                                    <a data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
                                       aria-controls="collapseFour">
                                        Schulungen und Lehrgänge
                                    </a>
                                </div>
                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                                     data-parent="#accordion">
                                    <div class="card-body">
                                        <p>
                                            Wir legen gro&szlig;en Wert darauf, unsere Kunden mit Ihrem neuen System
                                            vertraut zu machen, so dass alle Funktionalitäten vollauf genutzt werden
                                            k&ouml;nnen. Unser Ziel ist es, dass Sie Ihr LMS auch im Detail
                                            verstehen und so nicht von einem Dienstleister abhängig sind.
                                            Desweiteren unterstützen wir mit unseren Trainings Unternehmen und
                                            Bildungseinrichtungen, wie zum Beispiel die Steinbeis Co-Innovation
                                            Academy bei Zertifikatslehrgängen

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <testimonial/>


            <contact style="margin-bottom: 3rem;" v-bind:heading="'UND WAS KÖNNEN WIR FÜR SIE TUN?'" v-bind:text="'Bei uns stehen Sie und Ihr Projekt im Mittelpunkt. Entdecken Sie uns als attraktive Alternative zu einem Moodle-Partner'" v-bind:buttonlabel="'Sprechen Sie uns an!'"/>

        </div>


    </div>
</template>

<script>
    import Testimonial from "../components/Testimonial";
    import Contact from "../components/Contact";

    export default {
        async beforeMount() {
            if (this.$router.currentRoute.query.source) {
                this.$store.commit('source', this.$router.currentRoute.query.source)
            }
        },
        components: {
            Testimonial,
            Contact
        }
    };
</script>
