<template>
  <!-- Our Service Section Start-->
  <div class="our__service__sec">
    <div  v-if="showForm" class="container" id="formContainer">
      <div class="row">
        <div class="col-sm-12">
          <h1 class="underline">Sprechen Sie uns an</h1>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <p>Wir freuen uns darauf, von Ihnen zu h&ouml;ren!</p>
        </div>
      </div>
      <div class="row justify-content-center crow">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <div class="col-lg-10 col-sm-12 col-md-12">
            <form method="POST" ref="form" @submit.prevent="submit" id="contactForm">
              <div class="form-group">
                <input type="text" class="form-control" placeholder="Ihr Name" v-model="userName"/>
              </div>
              <div class="form-group">
                <input required
                  type="email" class="form-control"
                  placeholder="Ihre Email-Adresse*" v-model="userEmail"
                />
              </div>
              <div class="form-group">
                <textarea required
                  rows="9"
                  class="form-control"
                  placeholder="Ihre Nachricht an uns*"
                  v-model="userMsg"
                ></textarea>
              </div>
              <div class="form-group" style="text-align: left;">
                <input required type="checkbox" id="privacyaccepted" name="privacyaccepted"/>
                <label for="privacyaccepted" style="float: left; line-height: 20px;">Ich stimme der <a href="/privacy" target="_blank">Datenschutzerkl&auml;rung</a> zu. *</label>
              </div>
              <div class="form-group" style="text-align: left;clear: both;">
                <button type="button" class="bttn bttn-default" @click="handleSubmit">ABSENDEN</button>
              </div>
            </form>
          </div>
          <p>&nbsp;</p>
        </div>
        <div class="col-lg-4">
          <p>
            Gerne k&ouml;nnen Sie uns auch gerne per Email unter
            <a
              href="mailto:info@baltys.de"
            >info@baltys.de</a> kontaktieren.
            <br><br/>Telefonisch erreichen Sie uns zu Gesch&auml;ftszeiten unter<br/>
            <a
              href="tel:+4989998292470"
            > <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-telephone-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"/>
            </svg> +49 89 998 29 24 70</a>
          </p>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>
    <div v-if="successMessage" class="container" id="successContainer">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <p>
            Vielen Dank f&uuml;r Ihre Nachricht.
            <br />Wir werden uns schnellstm&ouml;glich bei Ihnen melden!
          </p>
        </div>
      </div>
    </div>
    <div v-if="showError" class="container" id="failureContainer">
      <div class="row justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-8">
          <p>
            Leider hat das Senden des Kontaktformulars nicht funktioniert.
            <br />Versuchen Sie es sp&auml;ter noch einmal oder schicken Sie uns eine Email an
            <a
              href="mailto:info@baltys.de"
            >info@baltys.de</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Our Service Section Close-->
</template>
<style>
  a[href^=tel] {
    border: 1px solid #ccc;
    border-radius: 5px;
    color: black;
    display: inline-block;
    font-style: normal;
    margin-top: 10px;
    padding: 3px 5px;
    text-decoration: none;
  }
</style>
<script>
import sendContactForm from '../functions/sendContactForm'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading
  },
  data() {
    return {
      userName: null,
      userEmail: null,
      userMsg: null,
      showError: false,
      showForm: true,
      successMessage: false,
      isLoading: false,
      fullPage: true
    }
  },
  methods: {
    async handleSubmit() {
      if (this.$refs.form.checkValidity()) {
        await this.submit();
      } else {
        this.$refs.form.reportValidity();
      }
    },
    async submit() {
      this.showError = false
      this.showForm = false
      this.isLoading = true
      try {
        await sendContactForm(this.userName, this.userEmail, this.userMsg, this.$store.state.source)
        this.isLoading = false
        this.successMessage = true
      } catch (e) {
        this.showError = true
      }
    }
  }
};
</script>
