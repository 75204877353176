<template>
  <div id="app">
    <base-layout />
  </div>
</template>
<script>
import baseLayout from "./Layout/LmsBaseLayout";
export default {
  components: {
    baseLayout
  }
};
</script>
